// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence
} from 'firebase/auth'
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore';
import { useEffect, useState } from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB0g6wyyWoo-q3EWF7ORCh7ink4zOGr8ao",
  authDomain: "tbis-34a2e.firebaseapp.com",
  projectId: "tbis-34a2e",
  storageBucket: "tbis-34a2e.appspot.com",
  messagingSenderId: "752652981385",
  appId: "1:752652981385:web:7045693386af243055e258",
  measurementId: "G-RWLFKFELLM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);


//#region Authentication
export async function login(email, password) {
  try {
    await setPersistence(auth, browserSessionPersistence);
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential;
  } catch (error) {
    throw new Error("Email and password do not match, try again.");
  }
}

export function logout(){
    signOut(auth);
}

export function resetPwdEmail(email){
  sendPasswordResetEmail(auth, email)
  .then(() => {
    return "Password reset email sent."
  })
  .catch((error) => {
    return error
  });
}

export function useAuth() {
  const [currentUser, setCurrentUser] = useState()

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user))
    return unsub
  }, [])

  return currentUser
}
//#endregion

//#region Firestore
export default getFirestore();
//#endregion