import { ThemeProvider } from '@emotion/react'
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore'
import { Fragment, useEffect, useState } from 'react'
import db, { useAuth } from '../Firebase'
import SendEmailToDealers from './SendEmailToDealers'

function NewsFeed() {
  const [newsItems, setNewsItems] = useState([])
  const [loading, setLoading] = useState(true)

  const [openSnackbar, setOpenSnackbar] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  const currentUser = useAuth()

  useEffect(() => {
    if (currentUser) {
      const q = query(collection(db, 'News'), orderBy('ProdDate', 'desc'))
      onSnapshot(q, (snapshot) => {
        snapshot.docs.forEach((doc) => {
          setNewsItems(snapshot.docs.map((doc) => ({ ...doc.data() })))
        })
        setLoading(false)
      })
    }
  }, [currentUser])

  async function handleAddNews() {
    
    if (title === '') {
      setErrorMessage('Title cannot be empty')
      setOpenSnackbar(true)
    }else if (message == ''){
      setErrorMessage('Message cannot be empty')
      setOpenSnackbar(true)
    }else{
      const collectionRef = collection(
        db,
        'News',
      )
      const tempDate = new Date()
      const payload = {
        Title: title,
        Contents: message,
        ProdDate: tempDate
      }
      await addDoc(collectionRef, payload);
      
      SendEmailToDealers(title, message)
    }

    setOpenAddDialog(false)
    setTitle('')
    setMessage('')
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Grid item xs={9}>
      <Card>
        <CardHeader title="Newsfeed" style={{ textAlign: 'left' }} />
        {loading ? (
          <CircularProgress sx={{ ml: 2, mb: 1 }} />
        ) : (
          <Fragment>
            {currentUser &&
              (currentUser.email === 'news@tbis.be' ||
                currentUser.email === 'admin@tbis.be') && (
                <Button
                  onClick={() => setOpenAddDialog(true)}
                  sx={{ ml: 2, mb: 1 }}
                  variant="outlined"
                >
                  Add News
                </Button>
              )}
            <Dialog
              open={openAddDialog}
              onClose={() => setOpenAddDialog(false)}
            >
              <DialogTitle>Add News Item</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Fill in the title and message for the new news item.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="title"
                  label="Title"
                  type="text"
                  fullWidth
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
                <TextField
                  margin="dense"
                  id="message"
                  label="Message"
                  type="text"
                  fullWidth
                  multiline
                  rows={10}
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenAddDialog(false)} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleAddNews} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
            <List component="nav" style={{ overflow: 'auto', maxHeight: 400 }}>
              {newsItems.map((item, index) => (
                <Fragment key={item.name}>
                  <ListItem>
                    <ListItemText
                      primary={
                        item.Title +
                        ' - ' +
                        item.ProdDate.toDate().toLocaleDateString('nl-BE')
                      }
                      secondary={item.Contents}
                    />
                  </ListItem>
                  {index < newsItems.length - 1 && <Divider />}
                </Fragment>
              ))}
            </List>
          </Fragment>
        )}
      </Card>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="error" onClose={handleCloseSnackbar}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default NewsFeed
