import { Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { default as LinkMat } from '@mui/material/Link'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { useRoute } from 'wouter'
import { Link } from 'wouter'

function Footer() {
  const [isHomepage] = useRoute('/')

  return (
    <Container
      maxWidth="lg"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        py: [3, 6],
      }}
    >
      <Grid container spacing={4} justify="flex-start">
        {isHomepage && (
          <Grid item xs={12} sm={12} key="Contact">
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" color="text.secondary">
                  Toshiba Tec Belgium Imaging Systems
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Z.1. Researchpark 20
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  BE-1731 Asse-Zellik
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  T +32 (0)2 600 41 01
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  onClick={() => {
                    window.open('https://be.toshibatec.eu', '_blank')
                  }}
                  className="buttonHover"
                >
                  be.toshiba.eu
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <LinkedInIcon
                      onClick={() => {
                        window.open(
                          'https://www.linkedin.com/company/toshiba-tec-belgium-imaging-systems/',
                          '_blank',
                        )
                      }}
                      fontSize="large"
                      className="buttonHover"
                    />
                  </Grid>
                  <Grid item>
                    <YouTubeIcon
                      onClick={() => {
                        window.open(
                          'https://www.youtube.com/channel/UCiMCEu7lwSfqBOlvWRbuVug',
                          '_blank',
                        )
                      }}
                      fontSize="large"
                      className="buttonHover"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.2835501728714!2d4.286629351645363!3d50.881462163994165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c16d8c25eabb%3A0x5eab1322c4fa3f85!2sToshiba%20TEC%20Belgium%20Imaging%20Systems!5e0!3m2!1snl!2sbe!4v1675788213353!5m2!1snl!2sbe"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
        <Typography variant="h6" color="text.primary" gutterBottom>
              Terms & Conditions
            </Typography>
          {window.navigator.language === 'nl' || window.navigator.language === 'nl-NL' ? (
            <>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                onClick={() => {
                  window.open(
                    'https://be.toshibatec.eu/nl/algemene-voorwaarden/',
                    '_blank',
                  )
                }}
                className="buttonHover"
              >
                Algemene Voorwaarden (Nederlands)
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                onClick={() => {
                  window.open(
                    'https://be.toshibatec.eu/nl/gegevensbescherming/',
                    '_blank',
                  )
                }}
                className="buttonHover"
              >
                Gegevensbescherming (Nederlands)
              </Typography>
            </>
          ) : window.navigator.language === 'fr' ||  window.navigator.language === 'fr-FR' ? (
            <>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                onClick={() => {
                  window.open(
                    'https://be.toshibatec.eu/fr/mentions-legales',
                    '_blank',
                  )
                }}
                className="buttonHover"
              >
                Mentions legales (Français)
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                onClick={() => {
                  window.open(
                    'https://be.toshibatec.eu/fr/protection-des-donnees',
                    '_blank',
                  )
                }}
                className="buttonHover"
              >
                Protection des donnees (Français)
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                onClick={() => {
                  window.open(
                    'https://www.toshibatec.eu/terms-and-conditions/',
                    '_blank',
                  )
                }}
                className="buttonHover"
              >
                Terms and Conditions
              </Typography>
              <Link to="/DataProtectionDeclaration">
              <Typography
                variant="subtitle1"
                color="text.secondary"
                className="buttonHover"
              >
                Data Protection Declaration
              </Typography>
              </Link>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Footer
