import React, { useState, useEffect } from 'react'
import { storage, useAuth } from '../Firebase'
import { ref, listAll, getDownloadURL } from 'firebase/storage'
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useLocation } from 'wouter'

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import FolderIcon from '@mui/icons-material/Folder'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HomeIcon from '@mui/icons-material/Home'
import { Box } from '@mui/system'

function FileExplorer({ rootPath }) {
  const [Items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPath, setCurrentPath] = useState('/' + rootPath + '/')
  const [location, setLocation] = useLocation()
  const currentUser = useAuth()

  useEffect(() => {
    if (currentUser === null && rootPath === 'Dealerfiles') {
      setLocation('/')
    }
    if (rootPath === "Documentation") {
      document.title = 'TBIS service | User Manuals'
    }else if (rootPath === "Dealerfiles") {
      document.title = 'TBIS service | Printer Drivers'
    }
    
  })

  useEffect(() => {
    const storageRef = ref(storage, currentPath)
    let fileLinkArray = []
    listAll(storageRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          fileLinkArray.push({
            name: folderRef.name,
            type: 'folder',
            path: folderRef.fullPath,
          })
        })

        // Use Promise.all to wait for all download URLs to resolve
        const downloadUrls = res.items.map((itemRef) => getDownloadURL(itemRef))
        Promise.all(downloadUrls)
          .then((urls) => {
            res.items.forEach((itemRef, index) => {
              fileLinkArray.push({
                name: itemRef.name,
                type: 'document',
                downloadUrl: urls[index],
              })
              console.log(urls[index]);
            })
            setItems(fileLinkArray)
            setLoading(false)
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [currentPath])

  function handleFolderClick(path) {
    setCurrentPath(path)
  }

  function handleHomeClick() {
    setCurrentPath('/' + rootPath + '/')
  }

  function handleBackClick() {
    const path = currentPath.split('/')
    if (path.length > 1) {
      path.pop()
      if (path[0] === '') {
        setCurrentPath('/' + rootPath + '/')
      } else {
        setCurrentPath(path.join('/'))
      }
    }
  }

  function handleFileClick(url) {
    window.open(url, '_blank')
  }

  return (
    <div
      className="home-layout"
      style={{ paddingLeft: '10%', paddingRight: '10%' }}
    >
      <h2 style={{marginTop:"0", marginBottom:"5px"}}>{rootPath === 'Documentation' ? 'User Manuals' : (rootPath === 'Drivers' ? 'Printer Drivers' : 'Downloads')}</h2>
      <p style={{marginTop:"0", marginBottom:"10px", color:"#e61e1e", fontSize:"16px"}}>To search files press ctrl+f and then type your search prompt.</p>

      <Button onClick={handleHomeClick}>
        <HomeIcon />
      </Button>
      <Button onClick={handleBackClick}>
        <ArrowBackIcon />
      </Button>
      <Breadcrumbs aria-label="breadcrumb">
        {currentPath.split('/').map((folder, index) => (
          <Link
            color="inherit"
            href="#"
            key={folder}
            onClick={() =>
              handleFolderClick(
                currentPath
                  .split('/')
                  .slice(0, index + 1)
                  .join('/') + '/',
              )
            }
          >
            <Typography>
              {folder === 'Documentation'
                ? 'User Manuals'
                : folder === 'Dealerfiles'
                ? 'Downloads'
                : folder}
            </Typography>
          </Link>
        ))}
      </Breadcrumbs>
      {loading ? (
        <CircularProgress sx={{ m: 1 }} />
      ) : (
        <Box
          sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
        >
          <List>
            {Items.filter((item) => item.name !== '_gsdata_').map(
              (item, index) => (
                <React.Fragment key={item.name}>
                  <ListItem
                    button
                    onClick={() =>
                      item.type === 'folder'
                        ? handleFolderClick(item.path)
                        : handleFileClick(item.downloadUrl)
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <ListItemIcon>
                      {item.type === 'folder' ? (
                        <FolderIcon />
                      ) : (
                        <InsertDriveFileIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItem>
                  {index < Items.length - 1 && <Divider />}
                </React.Fragment>
              ),
            )}
          </List>
        </Box>
      )}
    </div>
  )
}

export default FileExplorer
