import { Card, CardHeader, CardMedia, Grid } from '@mui/material'
import { useEffect } from 'react'
import { Link } from 'wouter'
import NewsFeed from './Components/Newsfeed'
import { useAuth } from './Firebase'
import GraphicMotif1 from './Images/GraphicMotif1.png'
import GraphicMotif2small from './Images/GraphicMotif2s.png'

function Home() {
  useEffect(() => {
    document.title = 'TBIS service | Home'
  }, [])

  const currentUser = useAuth()

  return (
    <div
      className="home-layout"
      style={{
        height: '100%',
        backgroundImage: `url(${currentUser ? GraphicMotif2small : GraphicMotif1})`,
        backgroundSize: '75%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: currentUser ? '100% 0' : '0 0',
        paddingTop: '3%',
        paddingBottom: '5%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="center"
        spacing={4}
        maxWidth="lg"
      >
        <Grid item xs={12}>
          {/* <Card>
            <CardHeader
              title="Toshiba TBIS Service Page"
              style={{ textAlign: 'left', fontWeight:'bold' }}
            />
            <CardMedia
              image={require('./Images/PrinterBanner.jpg')}
              title="Drivers icon"
              style={{ height: 0, paddingTop: '25%' }}
            />
          </Card> */}
        </Grid>
        {currentUser != undefined ? (
          <>
          <Link to="/Downloads">
          <Grid item xs={3} className="buttonHover">
            <Card>
              <CardHeader title="Downloads" style={{ textAlign: 'center' }} />
              <CardMedia
                image={require('./Icons/download.jpeg')}
                title="Downloads icon"
                style={{ height: 0, paddingTop: '100%' }}
              />
            </Card>
          </Grid>
        </Link>
          <NewsFeed />
          </>
        ) : (
          <>
          <Link to="/Drivers">
          <Grid item xs={3} className="buttonHover">
            <Card>
              <CardHeader title="Printer Drivers" style={{ textAlign: 'center' }} />
              <CardMedia
                image={require('./Icons/Drivers.jpeg')}
                title="Drivers icon"
                style={{ height: 0, paddingTop: '100%' }}
              />
            </Card>
          </Grid>
        </Link>
        <Link to="/UserManuals">
          <Grid item xs={3} className="buttonHover">
            <Card>
              <CardHeader
                title="User Manuals"
                style={{ textAlign: 'center' }}
              />
              <CardMedia
                image={require('./Icons/Documentation.jpeg')}
                title="Docs icon"
                style={{ height: 0, paddingTop: '100%' }}
              />
            </Card>
          </Grid>
        </Link>
            <Link to="/TechSupport">
              <Grid item xs={3} className="buttonHover">
                <Card>
                  <CardHeader
                    title="Tech Support"
                    style={{ textAlign: 'center' }}
                  />
                  <CardMedia
                    image={require('./Icons/Tech_Support.jpg')}
                    title="Tech Support Icon"
                    style={{ height: 0, paddingTop: '100%' }}
                  />
                </Card>
              </Grid>
            </Link>
            <Link to="/ServiceRequest">
              <Grid item xs={3} className="buttonHover">
                <Card>
                  <CardHeader title="Service Request" style={{ textAlign: 'center' }} />
                  <CardMedia
                    image={require('./Icons/Contact.jpg')}
                    title="Contact icon"
                    style={{ height: 0, paddingTop: '100%' }}
                  />
                </Card>
              </Grid>
            </Link>
          </>
        )}
      </Grid>
    </div>
  )
}

export default Home
