import { useEffect } from 'react'

function DataProtection() {
  useEffect(() => {
    document.title = 'TBIS service | Data Protection Declaration'
  }, [])
  return (
    <div
      className="home-layout"
      style={{ paddingLeft: '10%', paddingRight: '10%' }}
    >
      <h2>Data Protection Declaration</h2>
      <p>&nbsp;</p>
      <h3>1. Information regarding the collection of personal data</h3>
      <p>
        <br />
      </p>
      <p>
        1.1 Below, we will provide you with information about the collection of
        personal data in the case of conclusion of an employment contract as a
        client, professional company, supplier, business partner, or other
        contractual partner (for example, as part of an application or
        employment relationship) or when using our website. Personal data
        pertains to all data traceable to you personally, such as name, address,
        email addresses, user behaviour.
      </p>
      <p>
        <br />
      </p>
      <p>
        1.2. The responsible party as per Art. 4 (7) EU General Data Protection
        Regulation (GDPR) and other national data protection laws in the member
        states as well as other legal provisions related to data protection is:
      </p>
      <p>
        <br />
      </p>
      <p>Toshiba Tec Germany Imaging Systems GmbH</p>
      <p>Presidents: Toshiyuki Tokuno, Michael Becker</p>
      <p>Carl-Schurz-Stra&szlig;e 7</p>
      <p>D - 41460 Neuss</p>
      <p>
        <br />
      </p>
      <p>Tel: +49-(0)2131-1245-0</p>
      <p>Fax: +49-(0)2131-1245-402</p>
      <p>
        E-Mail:{' '}
        <a
          data-fr-linked="true"
          href="mailto:dataprotection@toshibatec-tgis.com"
        >
          dataprotection@toshibatec-tgis.com
        </a>
      </p>
      <p>
        <br />
      </p>
      <p>
        Our Data Protection Coordinator can be contacted using the information
        below:
      </p>
      <p>
        <br />
      </p>
      <p>Benny De Gelaen</p>
      <p>
        <a
          data-fr-linked="true"
          href="mailto:benny.degelaen@toshibatec-tgis.com"
        >
          benny.degelaen@toshibatec-tgis.com
        </a>
      </p>
      <p>Toshiba Tec Belgium Imaging Systems</p>
      <p>Z.1. Researchpark 20</p>
      <p>1731 Asse-Zellik - Belgi&euml;</p>
      <p>
        <br />
      </p>
      <p>
        1.3. When you contact us via post, telephone, email or contact form, the
        information you provide (your email address, your name and your
        telephone number, if applicable) will be stored by us to answer your
        questions. We delete the data gathered in this context once its storage
        is no longer necessary or we restrict its processing should statutory
        retention obligations exist. Furthermore, we collect and use personal
        data of the data subjects and users of our website only - among others,
        to provide a functioning website - as far as this is necessary in the
        context of our contents and services or in the context of a contract
        fulfilment. The collection and use of personal data from the data
        subjects and users of our website can generally only take place once
        consent has been granted by the users. An exception applies in cases
        where prior consent cannot be given for practical reasons and/or the
        processing of the data is permitted by legal regulations.
      </p>
      <p>
        <br />
      </p>
      <p>
        1.4. Should we wish to make use of subcontracted service providers for
        individual features of our offer, or use your information for
        promotional purposes, we will subsequently inform you about the
        respective processes in detail. In doing so we will also indicate the
        fixed criteria for the storage period.
      </p>
      <p>
        <br />
      </p>
      <h3>2. Your rights &ndash; Legal grounds</h3>
      <p>
        <br />
      </p>
      <p>
        2.1. You have the following rights in relation to us with regard to your
        personal data:
      </p>
      <p>- Right to access</p>
      <p>- Right to rectification or erasure</p>
      <p>- Right to restriction of processing</p>
      <p>- Right to objection to processing</p>
      <p>- Right to data transferability</p>
      <p>
        <br />
      </p>
      <p>
        2.2. You also have the right to complain about our processing of your
        personal data to a data protection monitoring body.
      </p>
      <p>
        <br />
      </p>
      <p>
        2.3. Where we obtain consent from the data subject for processing
        personal data, Art. 6 (1)(a) GDPR provides the legal grounds for the
        processing of personal data. When processing personal data required for
        the performance of a contract in which the data subject is a contracting
        party, Art. 6 (1)(b) GDPR provides the legal grounds. This also applies
        to processing procedures required for the implementation of
        pre-contractual measures. Where the processing of personal data is
        required for compliance with a legal obligation to which our company is
        subject, Art. 6 (1)(c) GDPR provides the legal grounds. In the case that
        the vital interests of the data subject or another natural person render
        the processing of personal data necessary, Art. 6 (1)(d) GDPR provides
        the legal grounds. If the processing is required to safeguard a
        legitimate interest of our company or a third party and the interests,
        fundamental rights and freedoms of the data subject do not override the
        first-named interests, Art. 6 (1)(f) GDPR provides the legal grounds for
        processing.
      </p>
      <p>
        <br />
      </p>
      <h3>3. Collection of personal data when visiting our website</h3>
      <p>
        <br />
      </p>
      <p>
        3.1. In the event of purely informational use of the website, i.e., if
        you don&rsquo;t register or otherwise provide us with information, we
        will only collect the personal data that your browser transfers to our
        server. If you wish to view our website, we collect the following data,
        which is technically necessary for us to show you our website and to
        ensure stability and security (legal grounds Art. 6 (1) Clause 1 (f)
        GDPR):
      </p>
      <p>- IP address</p>
      <p>- Date and time of the request</p>
      <p>- Time Zone Difference to Coordinated Universal Time (UTC)</p>
      <p>- Content of the request (specific page)</p>
      <p>- Access status/http status code</p>
      <p>- Each transmitted quantity of data</p>
      <p>- Website from which the request comes</p>
      <p>- Browser</p>
      <p>- Operating system and its interface</p>
      <p>- Language and version of the browser software</p>
      <p>
        <br />
      </p>
      <p>
        3.2. In addition to the aforementioned data, cookies are stored on your
        computer when you use our website. Cookies are small text files that are
        stored on your hard drive and assigned to the browser that you are using
        and allow the site that sets the cookie (here ours) to receive certain
        information. Cookies cannot run programs or transfer viruses to your
        computer. They serve to make the internet offer more user-friendly and
        effective overall.
      </p>
      <p>
        <br />
      </p>
      <p>3.3. Use of cookies</p>
      <p>
        3.3.1. This website uses the following types of cookies, the scope and
        operation of which are explained below:
      </p>
      <p>- Transient Cookies (for this 3.3.2.)</p>
      <p>- Persistent Cookies (for this 3.3.3.)</p>
      <p>
        <br />
      </p>
      <p>
        3.3.2. Transient cookies are automatically deleted when you close the
        browser. In particular, these include the session cookies. These store a
        so-called session ID, with which various requests from your browser can
        be assigned to the common session. This will allow your computer to be
        recognised when you return to our website. The session cookies are
        deleted when you log out or close the browser.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.3.3. Persistent cookies are automatically deleted after a specified
        period, which may differ depending on the cookie. You can delete the
        cookies in the security settings of your browser at any time.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.3.4. You can configure your browser settings according to your wishes
        and, for example, reject the acceptance of third-party cookies or all
        cookies. Please be aware that you may not be able to use all features of
        this website.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.5. All data is deleted as soon as it is no longer required to fulfil
        the purpose of its collection. If the data is collected for the
        provision of the website, this is the case when the respective session
        is completed.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.6. If the data is stored in log files, this is the case after seven
        days at the latest. Further storage is possible. The collection of data
        for the provision of the website and the storage of the data in log
        files is essential for the operation of the website. Consequently, there
        is no possibility of objection on the part of the user.
      </p>
      <p>
        <br />
      </p>
      <h3>
        4. Other features and offers of our website &ndash; external service
        providers
      </h3>
      <p>
        <br />
      </p>
      <p>
        4.1. In addition to the purely informative use of our website, we offer
        various services that you may be interested in using. To do this, you
        will generally need to provide other personal information that we use to
        provide the service and for which the aforementioned data processing
        principles apply.
      </p>
      <p>
        <br />
      </p>
      <p>
        4.2. In some instances, we use external service providers to process
        your data. These have been carefully selected and commissioned by us,
        are bound by our instructions and are audited regularly.
      </p>
      <p>
        <br />
      </p>
      <p>
        4.3. Where our service providers or partners have their headquarters in
        a state outside of the European Economic Area (EEA), we will inform you
        about the circumstances.
      </p>
      <p>
        <br />
      </p>
      <h3>5. Objection to or revocation of the processing of your data</h3>
      <p>
        <br />
      </p>
      <p>
        5.1. Should you have granted consent for the processing of your data you
        may modify or completely revoke this at any time without providing
        reasons. You can send this revocation to us (see Clause 1.2. &ndash;
        Responsible Party) by post, email, fax, or in the form that was used
        when issuing the declaration of consent. No extra costs are incurred in
        such cases, other than postage costs or transmission costs in accordance
        with existing basic tariffs. Such a revocation will impact the
        legitimacy of the processing of your personal data once you have sent it
        to us.
      </p>
      <p>
        <br />
      </p>
      <p>
        5.2. Where we support the processing of your personal data by referring
        to the balance of interests you can submit an objection to the
        processing. This is the case in particular if we do not require the
        processing to fulfil a contract with you. In the case of the following
        website uses, this will be additionally shown by us in the following
        description of the function. When exercising such an objection we ask
        that you provide the reasons for which we should not have processed your
        personal data as we have done. In the case that you provide an objection
        with reasons we will examine the situation and will either adjust or
        modify the data processing or indicate to you the mandatory reasons for
        continuing with the processing.
      </p>
      <p>
        <br />
      </p>
      <p>
        5.3. Of course, you may object to the processing of your personal data
        for marketing and data analysis purposes at any time. You may inform us
        about your objections to marketing by contacting us using the following
        contact information:{' '}
        <a data-fr-linked="true" href="mailto:werbung@toshibatec-tgis.com">
          werbung@toshibatec-tgis.com
        </a>
      </p>
      <p>
        <br />
      </p>
      <h3>6. Use of Google Analytics</h3>
      <p>
        <br />
      </p>
      <p>
        6.1. This website uses Google Analytics, a web analytics service
        provided by Google Inc. (&quot;Google&quot;). Google Analytics uses
        so-called &quot;cookies&quot;, text files that are stored on your
        computer and that allow an analysis of your use of the website. The
        information generated by the cookie about your use of this website is
        usually transferred to a Google server in the USA and stored there.
        However, due to the activation of IP anonymisation on this website, your
        IP address will be shortened by Google beforehand within member states
        of the European Union or another contracting state of the Agreement on
        the European Economic Area (EEA). The full IP address will only be sent
        to a Google server in the US and shortened there in exceptional cases.
        On behalf of the operator of this website, Google will use this
        information to evaluate your use of the website, to compile reports on
        website activity and to provide other services related to website usage
        and internet usage to the website operator.
      </p>
      <p>
        <br />
      </p>
      <p>
        6.2. The IP address provided by Google Analytics as part of Google
        Analytics will not be merged with other Google data.
      </p>
      <p>
        <br />
      </p>
      <p>
        6.3. You can prevent the storage of cookies with corresponding settings
        for your browser software; however, we point out that in this case you
        may not be able to use all features of this website in full. You may
        also prevent Google&apos;s collection of data (including your IP
        address) generated by the cookie from your use of the website from
        Google and the processing of such data by Google by downloading and
        installing the browser plug-in available at the following link:{' '}
        <a
          data-fr-linked="true"
          href="http://tools.google.com/dlpage/gaoptout?hl=en"
        >
          http://tools.google.com/dlpage/gaoptout?hl=en
        </a>
        .
      </p>
      <p>
        <br />
      </p>
      <p>
        6.4. This website uses Google Analytics with the extension
        &quot;_anonymizeIp()&quot;. As a result, IP addresses are processed in a
        shortened form; a direct personal reference can be excluded. If a
        personal reference is contained in the data collected about you, it will
        be immediately excluded and the personal data will be deleted
        immediately. &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        6.5. We use Google Analytics to analyse and regularly improve the use of
        our website. We can improve our offering with the statistics and make it
        more relevant for you as a user. For the exceptional cases in which
        personal data is transferred to the US, Google uses the standard
        contractual clauses of the European Commission.
      </p>
      <p>
        <br />
      </p>
      <p>
        6.6. Information about third-party providers: Google Dublin, Google
        Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353
        (1) 436 1001. Terms of service:{' '}
        <a
          data-fr-linked="true"
          href="http://www.google.com/analytics/terms/gb.html"
        >
          http://www.google.com/analytics/terms/gb.html
        </a>
        , Overview of data protection:{' '}
        <a
          data-fr-linked="true"
          href="http://www.google.com/intl/en/analytics/learn/privacy.html"
        >
          http://www.google.com/intl/en/analytics/learn/privacy.html
        </a>
        , as well as the privacy policy:{' '}
        <a
          data-fr-linked="true"
          href="http://www.google.de/intl/en/policies/privacy"
        >
          http://www.google.de/intl/en/policies/privacy
        </a>
        .
      </p>
      <p>
        6.7. We use Google Analytics on the basis of your informed and voluntary
        consent pursuant to Art. 6 para. 1 lit. a DSGVO, which you give us via
        your explicit selection in the cookie banner. You can revoke your given
        consent at any time with effect for the future by changing your cookie
        settings accordingly or deleting the cookies on your computer.
      </p>
      <p>
        <br />
      </p>
      <h3>7. Google Tag Manager</h3>
      <p>
        <br />
      </p>
      <p>
        This website uses the Google Tag Manager cookie to collect data on the
        website and forward it to the connected analytics tools. The Google Tag
        Manager itself does not store any personal data. The processing is based
        on consent (Art. 6 para. 1 lit a DSGVO) and can be revoked at any time
        with effect for the future.
      </p>
      <p>
        <br />
      </p>
      <h3>8. Social Media</h3>
      <p>
        <br />
      </p>
      <p>
        Since we are present on various social media platforms (digital
        technologies and media) using a business account (page or channel), we
        wish to inform you about the corresponding uses and data processing
        there.
      </p>
      <p>
        <br />
      </p>
      <p>
        When you visit our page or channel, the social media provider as the
        party responsible uses cookies to collect personal data from its users.
        Data may also be collected from visitors to the page or channel who are
        not logged in or registered with that social media provider.
      </p>
      <p>
        <br />
      </p>
      <p>
        The person responsible for the content of our business accounts is named
        in this privacy policy under section 1.1.
      </p>
      <p>
        <br />
      </p>
      <p>
        We are unable to confirm what user data a particular social media
        provider collects. Therefore, you use social media sites at your own
        risk. In addition, Toshiba does not have full access to the data
        collected or your profile data. We can only see the public information
        of your profile. You can choose which data you make available publicly
        by adjusting your social media settings.
      </p>
      <p>
        <br />
      </p>
      <p>
        We use your data when you use the chat function on our page or channel,
        which we use in particular to answer your queries. We collect data from
        users in this way so that we can provide you with the information you
        need and any quotes that you request.
      </p>
      <p>
        <br />
      </p>
      <p>
        The statistics listed below are used to continuously improve our online
        offer on our social media platforms and to better cater to our
        users&rsquo; interests. We cannot draw any conclusions on individual
        visitors from statistical data or link statistics to visitor profile
        data. You can adjust your social media settings to decide how targeted
        advertising is displayed to you.
      </p>
      <p>
        <br />
      </p>
      <p>
        If we have a statutory obligation to store your data, the data will be
        stored for the duration of the statutory retention period. Legal basis
        for processing data is Article 6(1)(f) of the GDPR.
      </p>
      <p>
        <br />
      </p>
      <p>8.1. YouTube</p>
      <p>
        For information on how data is collected and processed by YouTube,
        please see the YouTube Privacy Policy at{' '}
        <a
          data-fr-linked="true"
          href="https://policies.google.com/privacy?hl=en-GB&"
        >
          https://policies.google.com/privacy?hl=en-GB&amp;amp
        </a>
        ;gl=uk.
      </p>
      <p>
        <br />
      </p>
      <p>
        On the basis of legitimate interests, we receive anonymous statistics
        from YouTube on how our page is used, which contain the following
        information:
      </p>
      <p>
        <br />
      </p>
      <p>
        &bull; &nbsp; &nbsp; Followers: Number of people who follow Toshiba,
        including growth and development over a defined period of time
      </p>
      <p>
        &bull; &nbsp; &nbsp; Reach: Number of people who see a specific post;
        number of interactions on a post, which tells us what content is better
        received in the community than others
      </p>
      <p>
        &bull; &nbsp; &nbsp; Ad performance: How many people have been reached
        with a post or paid ad and interacted with it.
      </p>
      <p>
        <br />
      </p>
      <p>
        Toshiba receives personal data from YouTube if you actively share it
        with us in a private message on YouTube. We use your data (first name,
        last name, etc.) to respond to your queries, which are stored for this
        purpose.
      </p>
      <p>
        We also receive personal data from YouTube if you submit a form to us
        with pre-filled fields containing data from your profile; in clicking
        the button, you actively send the data to us.
      </p>
      <p>
        <br />
      </p>
      <p>
        We will delete the data from your enquiry/contact three months after the
        last message.
      </p>
      <p>
        <br />
      </p>
      <p>8.2. Xing</p>
      <p>
        For information on how data is collected and processed by Xing, please
        see the Xing Privacy Policy at{' '}
        <a
          data-fr-linked="true"
          href="https://privacy.xing.com/en/privacy-policy"
        >
          https://privacy.xing.com/en/privacy-policy
        </a>
        .
      </p>
      <p>
        <br />
      </p>
      <p>
        On the basis of legitimate interests, we receive anonymous statistics
        from Xing on how our page is used, which contain the following
        information:
      </p>
      <p>
        <br />
      </p>
      <p>
        &bull; &nbsp; &nbsp; Followers: Number of people who follow Toshiba,
        including growth and development over a defined period of time
      </p>
      <p>
        &bull; &nbsp; &nbsp; Reach: Number of people who see a specific post;
        number of interactions on a post, which tells us what content is better
        received in the community than others
      </p>
      <p>
        &bull; &nbsp; &nbsp; Ad performance: How many people have been reached
        with a post or paid ad and interacted with it.
      </p>
      <p>
        <br />
      </p>
      <p>
        Toshiba receives personal data from Xing if you actively share it with
        us in a private message on Xing. We use your data (first name, last
        name, etc.) to respond to your queries, which are stored for this
        purpose.
      </p>
      <p>
        <br />
      </p>
      <p>
        Toshiba also receives personal data from Xing if you submit a form to us
        with pre-filled fields containing data from your profile; in clicking
        the button, you actively send the data to us.
      </p>
      <p>
        <br />
      </p>
      <p>
        We will delete the data from your enquiry/contact three months after the
        last message.
      </p>
      <p>
        <br />
      </p>
      <p>8.3. LinkedIn</p>
      <p>
        Toshiba uses the technical platform and services of the LinkedIn Ireland
        Unlimited Company, Wilton Place, Dublin 2, Ireland for the information
        service provided on LinkedIn.
      </p>
      <p>
        <br />
      </p>
      <p>
        For information on how data is collected and processed by LinkedIn,
        please see the LinkedIn Privacy Policy at{' '}
        <a
          data-fr-linked="true"
          href="https://www.linkedin.com/legal/privacy-policy?_l=en_GB"
        >
          https://www.linkedin.com/legal/privacy-policy?_l=en_GB
        </a>
        .
      </p>
      <p>
        <br />
      </p>
      <p>
        On the basis of legitimate interests, we receive anonymous statistics
        from LinkedIn on how our page is used, which contain the following
        information:
      </p>
      <p>
        <br />
      </p>
      <p>
        &bull; &nbsp; &nbsp; Followers: Number of people who follow Toshiba,
        including growth and development over a defined period of time
      </p>
      <p>
        &bull; &nbsp; &nbsp; Reach: Number of people who see a specific post;
        Number of interactions on a post, which tells us what content is better
        received in the community than others
      </p>
      <p>
        &bull; &nbsp; &nbsp; Ad performance: How many people have been reached
        with a post or paid ad and interacted with it
      </p>
      <p>
        &bull; &nbsp; &nbsp; Number of clicks on a customised button (e.g.
        &lsquo;Visit website&rsquo;) to determine how a user uses the page.
      </p>
      <p>
        <br />
      </p>
      <p>
        Toshiba receives personal data from LinkedIn if you actively share it
        with us in a private message on LinkedIn. Toshiba also receives personal
        data from LinkedIn if you submit a form to us with pre-filled fields
        containing data from your profile; by clicking the button, you actively
        send the data to us. We use your data (first name, last name, etc.) to
        respond to your queries, which are stored for this purpose.
      </p>
      <p>
        <br />
      </p>
      <p>
        We will delete the data from your enquiry/contact three months after the
        last message.
      </p>
      <p>
        <br />
      </p>
      <p>8.4. Instagram</p>
      <p>
        Instagram is a service provided by Facebook Ireland Limited. Instagram
        is an online platform that allows users to share, edit, and filter
        photos and videos. Other users can then share, comment, or like the
        photos and videos. In addition, users can get in touch with each other
        by sending private messages. This notice is intended to clearly and
        transparently explain how your personal data is processed by us and at
        what point our processing ends.
      </p>
      <p>
        <br />
      </p>
      <p>
        For information on how data is collected and processed by Instagram,
        please see the Instagram Privacy Policy at{' '}
        <a
          data-fr-linked="true"
          href="https://www.facebook.com/help/instagram/155833707900388"
        >
          https://www.facebook.com/help/instagram/155833707900388
        </a>
        .
      </p>
      <p>
        <br />
      </p>
      <p>
        Toshiba&rsquo;s Instagram page is a business profile and therefore has
        the Instagram Insights function. This provides some of the data
        collected by Facebook during use to us for the purpose of statistical
        evaluation in a targeted form. This statistical evaluation relates only
        to the users, content, and activity on our Instagram page. The
        evaluation contains the following information:
      </p>
      <p>
        <br />
      </p>
      <p>
        &bull; &nbsp; &nbsp;Followers: Number of people who follow Toshiba,
        including growth and development over a defined period of time
      </p>
      <p>
        &bull; &nbsp; &nbsp;Reach: Number of people who see a specific post;
        number of interactions on a post, which tells us what content is better
        received in the community than others
      </p>
      <p>
        &bull; &nbsp; &nbsp;Ad performance: How many people have been reached
        with a post or paid ad and interacted with it
      </p>
      <p>&bull; &nbsp; &nbsp;Number of likes on our photos and videos</p>
      <p>&bull; &nbsp; &nbsp;Number of comments on our photos and videos</p>
      <p>&bull; &nbsp; &nbsp;Number of people who viewed a photo or video</p>
      <p>
        &bull; &nbsp; &nbsp;Number of times a photo or video has been shared
      </p>
      <p>&bull; &nbsp; &nbsp;Number of times a photo or video is reported</p>
      <p>
        &bull; &nbsp; &nbsp;Ads displayed on a page that the user no longer
        likes
      </p>
      <p>
        <br />
      </p>
      <p>
        When using certain interactive features on Instagram, comments or likes
        are visible to other users and to Toshiba as the Instagram page
        administrator. This means we can directly attribute users on the basis
        of the personal data disclosed. Toshiba does not control the interactive
        functionality and visibility of comments, likes, or other activities on
        the Toshiba Instagram page. Toshiba is therefore not responsible within
        the meaning of data protection law. The nature, scope, and duration of
        the processing and storage of personal data in this regard are at the
        sole discretion and responsibility of Facebook.
      </p>
      <p>
        <br />
      </p>
      <p>
        If our page or channel offers a chat function, Toshiba will use your
        data to answer your queries. The data collected in this way (first name,
        last name, etc.) is used to provide you with the information you need
        and answer any other questions you may have. When you contact us, we
        will be informed in particular of your user name, the text of the
        enquiry, and any other personal data that are necessary. We will store
        this data in order to respond to your queries. We will delete the data
        from your enquiry/contact three months after the last message.
      </p>
      <p>
        <br />
      </p>
      <p>8.5. Facebook</p>
      <p>
        For information on how data is collected and processed by Facebook,
        please see the Facebook Privacy Policy at{' '}
        <a data-fr-linked="true" href="https://www.facebook.com/policy.php">
          https://www.facebook.com/policy.php
        </a>
        .
      </p>
      <p>
        <br />
      </p>
      <p>
        You can adjust your Facebook settings to actively hide your likes or
        unfollow the page. Your profile will then no longer appear in the list
        of users of our site.
      </p>
      <p>
        <br />
      </p>
      <p>
        Toshiba receives anonymous statistics from Facebook on how our page is
        used,
      </p>
      <p>which contain the following information:</p>
      <p>
        <br />
      </p>
      <p>
        &bull; &nbsp; &nbsp; Followers: Number of people who follow Toshiba,
        including growth and development over a defined period of time
      </p>
      <p>
        &bull; &nbsp; &nbsp; Reach: Number of people who see a specific post;
        number of interactions on a post, which tells us what content is better
        received in the community than others
      </p>
      <p>
        &bull; &nbsp; &nbsp; Ad performance: How many people have seen an ad
      </p>
      <p>
        &bull; &nbsp; &nbsp; Demographic: Average age, gender, location, and
        language of visitors.
      </p>
      <p>
        <br />
      </p>
      <p>
        Toshiba receives personal data from Facebook if you actively share it
        with us in a private message on Facebook. We use your data (first name,
        last name, etc.) to respond to your queries, which are stored for this
        purpose and deleted three months after the last message.
      </p>
      <p>
        <br />
      </p>
      <h3>9. Google Maps integration</h3>
      <p>
        <br />
      </p>
      <p>
        9.1. We use Google Maps on our website. This allows us to show you
        interactive maps directly on the website and allows you to conveniently
        use the map feature.
      </p>
      <p>
        <br />
      </p>
      <p>
        9.2. By visiting the website, Google receives the information that you
        have accessed the corresponding subpage of our website. In addition, the
        data referred to in Clause 3 of this declaration will be transmitted.
        This is done regardless of whether Google provides an account that you
        are logged in to, or if there is no user account. If you&apos;re logged
        in via Google, your data will be assigned directly to your account. If
        you do not wish this to be associated with your profile on Google, you
        must log out before activating the button. Google stores your data as
        usage profiles and uses them for purposes of advertising, market
        research and/or tailor-made website design. Such an evaluation is done
        in particular (even for non-logged in users) to provide needs-based
        advertising and to inform other users of the social network about your
        activities on our website. You have a right of objection to the
        formation of these user profiles, whereby you must contact Google to
        exercise them.
      </p>
      <p>
        <br />
      </p>
      <p>
        9.3. For more information on the purpose and scope of the data
        collection and its processing by the plug-in provider, please refer to
        the provider&apos;s privacy policy. There, you will also find more
        information about your rights and settings options to protect your
        privacy:{' '}
        <a
          data-fr-linked="true"
          href="https://www.google.de/intl/en/policies/privacy"
        >
          https://www.google.de/intl/en/policies/privacy
        </a>
        . Google also processes personal information in the US and uses the EU
        standard contractual clauses of the European Commission.
      </p>
      <p>
        <br />
      </p>
      <h3>10. Integration of YouTube videos</h3>
      <p>
        <br />
      </p>
      <p>
        10.1. We&apos;ve included YouTube videos in our online offering, which
        are stored on{' '}
        <a data-fr-linked="true" href="http://www.youtube.com">
          http://www.youtube.com
        </a>{' '}
        and are directly accessible from our website. These are all embedded in
        &quot;enhanced privacy mode&quot;, which means that no data about you as
        a YouTube user will be transferred if you do not play the videos. The
        data mentioned in Para. 2 is only transferred when the videos are
        played. We have no influence on this data transfer.
      </p>
      <p>
        <br />
      </p>
      <p>
        10.2. By visiting the website, YouTube receives the information that you
        have accessed the corresponding subpage of our website. In addition, the
        data referred to in Clause 3 of this declaration will be transmitted.
        This is done regardless of whether YouTube provides an account that you
        are logged in to, or if there is no user account. If you&apos;re logged
        in via Google, your data will be assigned directly to your account. If
        you do not wish to be associated with your profile on YouTube, you must
        log out before activating the button. YouTube stores your data as usage
        profiles and uses them for purposes of advertising, market research
        and/or tailor-made website design. Such an evaluation is done in
        particular (even for non-logged in users) to provide needs-based
        advertising and to inform other users of the social network about your
        activities on our website. You have a right of objection to the
        formation of these user profiles, whereby they must be directed to
        YouTube for the exercise.
      </p>
      <p>
        <br />
      </p>
      <p>
        10.3. Please read the privacy policy for more information on the purpose
        and scope of your data collection and processing through YouTube. There
        you will also get further information about your rights and settings
        options to protect your privacy:{' '}
        <a
          data-fr-linked="true"
          href="https://www.google.de/intl/en/policies/privacy"
        >
          https://www.google.de/intl/en/policies/privacy
        </a>
        . Google also processes personal information in the US and uses the
        standard contractual clauses of the European Commission.
      </p>
      <p>
        <br />
      </p>
      <h3>11. Contact form and email contact</h3>
      <p>
        <br />
      </p>
      <p>
        11.1. There is a contact form on our web page which can be used to get
        in touch electronically. If you make use of this option, the data you
        enter into the input screen will be transferred to and saved by us. This
        data includes:
      </p>
      <p>Required fields:</p>
      <p>- Name and surname</p>
      <p>- Country</p>
      <p>- Email address</p>
      <p>
        <br />
      </p>
      <p>Optional:</p>
      <p>- Type of request</p>
      <p>- Company name</p>
      <p>- Phone number</p>
      <p>
        <br />
      </p>
      <p>
        At the time of sending the message the following data is also saved:
      </p>
      <p>a) IP address</p>
      <p>b) Date and time of sending the request</p>
      <p>
        <br />
      </p>
      <p>
        For the processing of the data, your consent will be obtained within the
        scope of the sending process and you will be referred to the data
        protection declaration. Alternatively, you can get in touch via the
        email address provided. In this case, the user&apos;s personal data
        transmitted with the email will be saved. In this connection, there is
        no forwarding of the data to third parties. The data is solely used for
        processing the conversation.
      </p>
      <p>
        <br />
      </p>
      <p>
        11.2. The legal grounds for data processing, in the case that consent is
        granted by the user, are provided by Art. 6 (1)(a) GDPR. The legal
        grounds for data processing, transferred as part of an email, are
        provided by Art. 6 (1)(f) GDPR. If the purpose of the email/mail contact
        is the performance of a contract, Art. 6 (1)(b) GDPR provides additional
        legal grounds for processing.
      </p>
      <p>
        <br />
      </p>
      <p>
        11.3 The processing of personal data from the input screen solely serves
        us for contact purposes. In the case of contact being established by
        email, the required justified interests in the processing of the data
        are also included. Other personal data processed during the sending
        process serve to prevent misuse of the contact form and to ensure the
        security of our IT systems.
      </p>
      <p>
        <br />
      </p>
      <p>
        11.4. The data is deleted as soon as it is no longer required to fulfil
        the purpose of its collection. This is the case for the personal data
        from the contact form input screen and that transferred by email if the
        respective conversation with the user has been brought to a close. The
        conversation has been brought to a close if circumstances indicate that
        the relevant subject matter has been conclusively clarified.
      </p>
      <p>
        <br />
      </p>
      <p>
        11.5. The user has the option at all times to revoke their consent to
        the processing of their personal data. Should the user contact us via
        email, they can modify or completely revoke the storage of their
        personal data with immediate effect for the future at any time without
        providing any reasons. In such a case, the conversation cannot be
        continued. This revocation can be sent to us by post, email, fax (for
        address details etc. see Clause 1.2.), or in the form that was used when
        issuing the declaration of consent. Personal data stored during the
        establishment of contact is deleted in this case.
      </p>
      <p>&nbsp;</p>
      <h3>
        12. Sensitive data and data relevant to the German Equal Treatment Act
        (AGG)
      </h3>
      <p>
        <br />
      </p>
      <p>
        We would like to point out that, based on existing data protection and
        AGG laws, our company does not desire, particularly within the scope of
        an application process, the sharing of particularly sensitive
        information, such as, for example, photos, information on family status
        - with the exception of a potential status as a severely disabled
        person, should the applicant freely choose to disclose this.
      </p>
      <p>
        <br />
      </p>
    </div>
  )
}

export default DataProtection
