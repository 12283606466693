import React, { useEffect, useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import {
  Button,
  FormControl,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
} from '@mui/material'
import { theme } from './Components/InternalThemeProvider'
import { useLocation } from 'wouter'
import axios from 'axios'
import db, { useAuth } from './Firebase'
import { collection, query, where, getDocs } from 'firebase/firestore'
import AddDeviceDialog from "./Components/AddDeviceDialog";

function Contact() {
  useEffect(() => {
    document.title = 'TBIS service | Service Request'
  }, [])

  const [SerialNo, setSerialNo] = useState('')
  const [CompanyName, setCompanyName] = useState('')
  const [TelNo, setTelNo] = useState('')
  const [Email, setEmail] = useState('')
  const [Description, setDescription] = useState('')

  const [openDialog, setOpenDialog] = useState(false)
  const [addDeviceDialogOpen, setAddDeviceDialogOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [issueReported, setIssueReported] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [dialogMessage, setDialogMessage] = useState()
  const [currentDevice, setCurrentDevice] = useState()
  
  const currentUser = useAuth()

  const [location, setLocation] = useLocation()

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const phoneNumberRegex = /^(\+\d{1,3})?(\d{9,})$/

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    const q = query(
      collection(db, 'DeviceDetails'),
      where('serialNo', '==', SerialNo),
    )
    const querySnapshot = await getDocs(q)
    let currentDeviceVar
    await querySnapshot.forEach(async (doc) => {
      // use await here
      //await setCurrentDevice(doc.data());
      currentDeviceVar = doc.data()
    })

    if (SerialNo == '' || SerialNo == null) {
      handleFormHandlingError('Serial number cannot be empty.')
    }  else if (TelNo == '' || TelNo == null) {
      handleFormHandlingError('Phone number cannot be empty.')
    } else if (Email == '' || Email == null) {
      handleFormHandlingError('Email Address cannot be empty.')
    } else if (Description == '' || Description == null) {
      handleFormHandlingError('Description cannot be empty.')
    } else if (!emailRegex.test(Email)) {
      handleFormHandlingError('Email is not valid.')
    } else if (!phoneNumberRegex.test(TelNo)) {
      handleFormHandlingError('Phone number is not valid.')
    } else if (currentDeviceVar == undefined) {
      handleSerialNotFoundError('Serial number cannot be found.')
    } else {
      setOpenBackdrop(true)
      const HTMLMessage =
        '<h2>Automatic support request from ' +
        CompanyName +
        ' for machine nr: ' +
        SerialNo +
        '</h2>' +
        '<h3>Company name:</h3><p> ' +
        CompanyName +
        '</p><h3>Machine serial no:</h3><p> ' +
        SerialNo +
        '</p><h3>Phone number filled in by client:</h3><p> ' +
        TelNo +
        '</p><h3>Email filled in by client:</h3><p> ' +
        Email +
        '</p><h3>Issue description:</h3><p> ' +
        Description +
        '</p><h3>Data retrieved from database: </h3><table><tr><td><b>Company name: </b></td><td>' +
        currentDevice.customerName +
        '</td></tr><tr><td><b>Address: </b></td><td>' +
        currentDevice.address +
        ', ' +
        currentDevice.zip +
        ' ' +
        currentDevice.city +
        '</td></tr><tr><td><b>Email: </b></td><td>' +
        currentDevice.email +
        '</td></tr><tr><td><b>Phone number: </b></td><td>' +
        currentDevice.tel +
        '</td></tr><tr><td><b>Model and serial number: </b></td><td>' +
        currentDevice.model +
        ', ' +
        currentDevice.serialNo +
        '</td></tr></table>' +
        '</p><br><p><b>This email has been sent automatically, you cannot respond to it.</b></p>'
      const params = {
        dest: 'service@tbis.be',
        //dest: 'tbis.service@gmail.com',
        subj:
          'Support request from ' +
          CompanyName +
          ' for machine nr: ' +
          SerialNo,
        cont: HTMLMessage,
      }
      axios
        .get('https://us-central1-tbis-34a2e.cloudfunctions.net/sendMail', {
          params,
        })
        .then()
        .catch((error) => console.log(error))
      // Clear form fields after submit
      setSerialNo('')
      setCompanyName('')
      setTelNo('')
      setEmail('')
      setDescription('')
      setIssueReported(true)
      setDialogMessage(
        'Your issue has been reported. We will get back to you as soon as possible.',
      )
      setOpenDialog(true)
      setOpenBackdrop(false)
      setOpenSnackbar(false)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleAddDeviceDialogOpen = () => {
    setAddDeviceDialogOpen(true);
  };

  const handleCloseAddDeviceDialog = () => {
    setAddDeviceDialogOpen(false);
  };

  const handleRedirectHome = () => {
    // Code for redirecting to homepage goes here
    setLocation('/')
  }

  function handleFormHandlingError(errorMessage) {
    setOpenBackdrop(false)
    setErrorMessage(errorMessage)
    setOpenSnackbar(true)
  }

  function handleSerialNotFoundError(errorMessage) {
    setOpenBackdrop(false)
    setDialogMessage(errorMessage)
    setOpenDialog(true)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackbar(false)
  }

  const handleSerialEnter = async (event) => {
    const q = query(
      collection(db, 'DeviceDetails'),
      where('serialNo', '==', SerialNo.replace(' ', '')),
    )
    const querySnapshot = await getDocs(q)
    let currentDeviceVar
    await querySnapshot.forEach(async (doc) => {
      currentDeviceVar = doc.data()
    })
    setCurrentDevice(currentDeviceVar)
    setCompanyName(currentDeviceVar.customerName)
    // setTelNo(currentDeviceVar.tel.replace(/\s/g, "").replace("-", ""))
    // setEmail(currentDeviceVar.email)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        className="home-layout"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: 'rgb(255,255,255)',
          background:
            'linear-gradient(225deg, rgba(0,0,0,1) 0%, rgba(230,30,30,1) 50%, rgba(255,255,255,1) 100%)',
        }}
      >
        <Card>
          <CardContent>
          {currentUser && (currentUser.email === 'admin@tbis.be' ) && (
              <Button variant='outlined' onClick={handleAddDeviceDialogOpen}>
              Add device
            </Button>
            )}
            
            <FormControl
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h2>Service Request Form</h2>
              <TextField
                id="SerialNo"
                label="Serial number"
                variant="outlined"
                value={SerialNo}
                onChange={(event) => setSerialNo(event.target.value)}
                onBlur={handleSerialEnter}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleSerialEnter()
                  }
                }}
                sx={{ m: 1, width: 400 }}
                required={true}
              />
              <TextField
                id="CompanyName"
                label="Company name"
                variant="outlined"
                value={CompanyName}
                onChange={(event) => setCompanyName(event.target.value)}
                sx={{ m: 1, width: 400 }}
                autoComplete="off"
              />
              <TextField
                id="TelNo"
                label="Telephone number"
                variant="outlined"
                value={TelNo}
                onChange={(event) => setTelNo(event.target.value)}
                sx={{ m: 1, width: 400 }}
                required={true}
                helperText="Format example +12345678901"
              />
              <TextField
                id="Email"
                label="Email Address"
                variant="outlined"
                value={Email}
                onChange={(event) => setEmail(event.target.value)}
                sx={{ m: 1, width: 400 }}
                required={true}
              />
              <TextField
                id="ProblemDescription"
                label="Problem description"
                variant="outlined"
                multiline
                rows={10}
                value={Description}
                onChange={(event) => setDescription(event.target.value)}
                sx={{ m: 1, width: 500, color: 'white' }}
                required
              />
              <Button variant="contained" type="submit" onClick={handleSubmit}>
                Send
              </Button>
            </FormControl>
          </CardContent>
        </Card>
      </div>

      <Backdrop open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {issueReported ? 'Issue reported' : 'Report could not be handled'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={issueReported ? handleRedirectHome : handleCloseDialog}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <AddDeviceDialog open={addDeviceDialogOpen} handleCloseAddDeviceDialog={handleCloseAddDeviceDialog} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="error" onClose={handleCloseSnackbar}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  )
}

export default Contact
