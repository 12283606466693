import axios from 'axios'
import { collection, getDocs, query } from 'firebase/firestore'
import { useEffect } from 'react'
import db from '../Firebase'

async function SendEmailToDealers(title, text) {
  let mailList = []

  const q = query(collection(db, 'EmailList'))
  const querySnapshot = await getDocs(q)
  await querySnapshot.forEach(async (doc) => {
    mailList.push(doc.id)
  })
  const HTMLMessage = '<h2>' + title + '</h2>' + '<p>' + text + '</p>' + '<br/><a href="tbis.be">tbis.be</a><br/><p><i>To be excluded from the mailing list receiving updates from the TBIS service page please contact us.</i></p>'
  const params = {
    subj: 'Update published on tbis.be',
    cont: HTMLMessage,
  };
  
  mailList.forEach((destination) => {
    params.dest = destination;
  
    axios
      .get('https://us-central1-tbis-34a2e.cloudfunctions.net/sendMail', {
        params,
      })
      .then()
      .catch((error) => console.log(error));
  });
}

export default SendEmailToDealers
