import ToshibaSans from '../fonts/TTToshibaSans-Regular.ttf'
import { ThemeProvider, createTheme } from '@mui/material/styles'

export let theme;

theme = createTheme({
  typography: {
    fontFamily: 'ToshibaSans, Arial',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'ToshibaSans';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Raleway'), local('Raleway-Regular'), url(${ToshibaSans}) format('woff2');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
    },
  },
  palette: {
    primary: {
      main: '#e61e1e',
    },
    dark: {
      main: '#ffffff',
    },
    light: {
      main: '#000000',
    },
  },
})

function Contact() {
  return <></>
}

export default Contact
