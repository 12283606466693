import { AppBar, Button, Toolbar, Typography } from '@mui/material'
import { Link } from 'wouter'
import Logoutbtn from './Logoutbtn'
import { default as LinkMat } from '@mui/material/Link'
import { useAuth } from '../Firebase'
import { Box } from '@mui/system'
import { useRoute } from 'wouter'

function DealerTab() {
  let currentUser = ''
  currentUser = useAuth()

  if (currentUser != undefined) {
    if (currentUser.email === 'admin@tbis.be') {
      return (
        <>
          <Link to="/Downloads">
            <LinkMat
              variant="button"
              color="text.primary"
              underline="none"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Downloads
            </LinkMat>
          </Link>
          <Link to="/ServiceRequest">
            <LinkMat
              variant="button"
              color="text.primary"
              underline="none"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Service Request
            </LinkMat>
          </Link>
        </>
      )
    } else {
      return (
        <Link to="/Downloads">
          <LinkMat
              variant="button"
              color="text.primary"
              underline="none"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Downloads
            </LinkMat>
        </Link>
      )
    }
  } else {
    return (
      <>
        <Link to="/TechSupport">
          <LinkMat
            variant="button"
            color="text.primary"
            underline="none"
            href="#"
            sx={{ my: 1, mx: 1.5 }}
          >
            Tech Support
          </LinkMat>
        </Link>
        <Link to="/ServiceRequest">
          <LinkMat
            variant="button"
            color="text.primary"
            underline="none"
            href="#"
            sx={{ my: 1, mx: 1.5 }}
          >
            Service Request
          </LinkMat>
        </Link>
      </>
    )
  }
}

function Header() {
  const [isHomepage] = useRoute('/')
  const [isLoginpage] = useRoute('/Login')
  const [isResetPwdpage] = useRoute('/PasswordReset')

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      //   sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Link to="/">
            <img
              src="./assets/Images/Toshiba_Logo_Rood_CMYK.png"
              height="20px"
              className="buttonHover"
            />
          </Link>
          <Typography sx={{ fontWeight: 'bold', ml: 1 }}>
            Toshiba Tec Belgium Imaging Systems
          </Typography>
        </div>
        {!isLoginpage && !isResetPwdpage && <Logoutbtn />}
      </Toolbar>
      {!isLoginpage && !isResetPwdpage && (
        <Toolbar sx={{ flexWrap: 'wrap' }} variant="dense">
          <nav>
            <Link to="/Drivers">
              <LinkMat
                variant="button"
                color="text.primary"
                underline="none"
                href="#"
                sx={{ my: 1, mx: 1.5 }}
              >
                Printer Drivers
              </LinkMat>
            </Link>
            <Link to="/UserManuals">
              <LinkMat
                variant="button"
                color="text.primary"
                underline="none"
                href="#"
                sx={{ my: 1, mx: 1.5 }}
              >
                User Manuals
              </LinkMat>
            </Link>
            <DealerTab />
          </nav>
        </Toolbar>
      )}
      {isHomepage && (
        <Box
          component="img"
          sx={{
            background: 'rgba(178,180,182,1)',
            // background:'linear-gradient(330deg, rgba(0,101,176,1) 0%, rgba(178,180,182,1) 50%, rgba(255,255,255,1) 100%)',
            background:
              'linear-gradient(2deg, rgba(178,180,182,1) 0%, rgba(245,245,245,1) 100%)',
          }}
          alt="Printer gamma."
          src={require('../Images/6_models_B_WhiteBG2.jpg')}
        />
      )}
    </AppBar>
  )
}

export default Header
