import { isEmpty } from '@firebase/util'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
} from '@mui/material'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import db from '../Firebase'

const AddDeviceDialog = ({ open, handleCloseAddDeviceDialog }) => {
  const [data, setData] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false)
  const [error, setError] = useState('')

  function divideData(data) {
    const elements = data.split('\t')
    const device = {
      'Install Date': elements[0],
      Duration: elements[1],
      'End Contract': elements[2],
      Sharepoint: elements[3],
      'Serial N°': elements[4],
      Model: elements[5],
      Name: elements[6],
      'Customer Name': elements[7],
      Address: elements[8],
      ZIP: elements[9],
      City: elements[10],
      'IP Address': elements[11],
      'Floor-Location': elements[12],
      'MT Contract': elements[13],
      RT: elements[14],
      Tel: elements[15],
      Email: elements[16],
      'Contact 1': elements[17],
      'Mail 1': elements[18],
      'Tel 1': elements[19],
      'Contact 2': elements[20],
      'Mail 2': elements[21],
      'Tel 2': elements[22],
      'Owner HW': elements[23],
      'MT Contract': elements[24],
      Lead: elements[25],
      Parking: elements[26],
      RADF: elements[27],
      DSDF: elements[28],
      'Drawer 1': elements[29],
      'Drawer 2': elements[30],
      'Drawer 3': elements[31],
      'Drawer 4': elements[32],
      LCF: elements[33],
      'Ext. LCF': elements[34],
      Fax: elements[35],
      Finisher: elements[36],
    }
    addDeviceDetailsToFS(device)
  }

  async function addDeviceDetailsToFS(device) {
    if (data === '') {
      setOpenErrorSnackbar(true)
      setData('')
    } else {
      try {
        const docRef = doc(db, 'DeviceDetails', device['Serial N°'])

        const payload = {
          address: device['Address'],
          city: device['City'],
          customerName: device['Customer Name'],
          email: device['Email'],
          model: device['Model'],
          serialNo: device['Serial N°'].replace(' ', ''),
          zip: device['ZIP'],
        }
        await setDoc(docRef, payload)
        handleSuccess()
      } catch (error) {
        console.error(error)
        setOpenErrorSnackbar(true)
      }
      setData('')
    }
  }

  const handleAdd = () => {
    let modifiedData = data

    if (data.endsWith('\n')) {
      modifiedData = data.slice(0, -1)
    }
    if (modifiedData.includes('\n')) {
      let dataArray = modifiedData.split('\n')

      dataArray.forEach((deviceData) => {
        divideData(deviceData)
      })
    } else {
      divideData(modifiedData)
    }
    handleCloseAddDeviceDialog()
  }

  const handleSuccess = () => {
    setError('Device has been successfully added.')
    setOpenSnackbar(true)
  }

  return (
    <>
      <Dialog open={open} onClose={handleCloseAddDeviceDialog}>
        <DialogTitle>Add a new device</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please paste the selected rows from excel as you would paste in
            Sharepoint:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="data"
            type="text"
            fullWidth
            multiline
            rows={10}
            value={data}
            onChange={(e) => setData(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDeviceDialog}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={3000}
      >
        <Alert severity="success">"Device successfully added."</Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        onClose={() => setOpenErrorSnackbar(false)}
        autoHideDuration={3000}
      >
        <Alert severity="error">"An error has occured, try again later."</Alert>
      </Snackbar>
    </>
  )
}

export default AddDeviceDialog
