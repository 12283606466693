import React from 'react'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import GlobalStyles from '@mui/material/GlobalStyles'
import Container from '@mui/material/Container'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './Components/InternalThemeProvider'
import { default as LinkMat } from '@mui/material/Link'
import { useAuth, logout } from './Firebase'

import './index.css'

import Home from './Home.js'
import FileExplorer from './Components/FileExplorer'
import Contact from './Contact.js'
import Login from './Login.js'
import Header from './Components/Header'
import Footer from './Components/Footer'

import { Route } from 'wouter'

import PasswordReset from './PasswordReset'
import FindDriver from './Components/FindDriver'
import DataProtection from './DataProtection'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
      />
      <Header />
      {/* Hero unit */}
      <Container disableGutters maxWidth="100vw" component="main">
        <Route path="/">
          <Home />
        </Route>
        <Route path="/Drivers">
          <FileExplorer rootPath={'Drivers'} />
          {/* <FindDriver /> */}
        </Route>
        <Route path="/UserManuals">
          <FileExplorer rootPath={'Documentation'} />
        </Route>
        <Route path="/Login">
          <Login />
        </Route>
        <Route path="/PasswordReset">
          <PasswordReset />
        </Route>
        <Route path="/DataProtectionDeclaration">
          <DataProtection />
        </Route>
        <Route
          path="/TechSupport"
          component={() => {
            window.location.href = 'https://get.teamviewer.com/6idhef2'
            return null
          }}
        />
        <Route path="/ServiceRequest">
          <Contact />
        </Route>
        <Route path="/Downloads">
          <FileExplorer rootPath={'Dealerfiles'} />
        </Route>
      </Container>
      {/* End hero unit */}
      <Footer />
    </ThemeProvider>
  </>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
