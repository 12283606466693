import { useAuth, logout } from '../Firebase'

import { Link, useLocation } from 'wouter'
import { Button } from '@mui/material'

let isLoggedIn = true

function Logoutbtn() {
  const [location, setLocation] = useLocation()
  const currentUser = useAuth()
  let MenuItem

  async function handleLogout() {
    try {
      await logout()
      setLocation('/')
    } catch {
      alert('Uitloggen mislukt.')
    }
  }

  if (currentUser != undefined) {
    MenuItem = (
      <Button
        href="#"
        variant="contained"
        sx={{ my: 1, mx: 1.5, fontWeight:'bold',p:1 }}
        onClick={handleLogout}
      >
        Logout ({currentUser?.email})
      </Button>
    )
  } else {
    MenuItem = (
      <Link to="/Login">
            <Button
              href="#"
              variant="contained"
              sx={{ my: 1, mx: 1.5, fontWeight:'bold' }}
            >
              Login
            </Button>
          </Link>
    )
  }

  return MenuItem
}

export default Logoutbtn
