import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LoginIcon from '@mui/icons-material/Login'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'

import { login, useAuth } from './Firebase'
import { useLocation } from 'wouter'
import { Card, CardContent, FormControl, FormHelperText } from '@mui/material'
import { theme } from './Components/InternalThemeProvider'

function Login() {
  const [location, setLocation] = useLocation()
  const [error, setError] = React.useState('')

  const currentUser = useAuth()
  if (currentUser != undefined) {
    setLocation('/')
  }

  async function handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    if (data.get('email') === '') {
      setError('Fill in email address.')
    } else if (data.get('password') === '') {
      setError('Fill in password.')
    } else {
      try {
        const userCredential = await login(data.get('email'), data.get('password'));
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    }
  }

  document.title = 'TBIS service | Login'

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        component="main"
        maxWidth="100%"
        className="home-layout"
        sx={{
          background: 'rgb(255,255,255)',
          background:
            'linear-gradient(225deg, rgba(0,0,0,1) 0%, rgba(230,30,30,1) 50%, rgba(255,255,255,1) 100%)',
          mx: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh',
        }}
      >
        <Card sx={{ maxWidth: 400 }}>
          <CardContent>
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 2, height: 50, width: 50, bgcolor: '#e61e1e' }}>
                <LoginIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                {/* <FormControlLabel
      control={<Checkbox value="remember" color="primary" />}
      label="Remember me"
    /> */}
                {error && <FormHelperText error>{error}</FormHelperText>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                >
                  Sign In
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setLocation('/PasswordReset')
                  }}
                  sx={{ mt: 1, mb: 2 }}
                >
                  Reset Password
                </Button>
              </Box>
            </FormControl>
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  )
}

export default Login
